import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import ReCAPTCHA from "react-google-recaptcha"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLocationArrow,
  faMailBulk,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"
import Layout from "../components/layout"
import _ from "lodash"
import Swal from "sweetalert2"
import SEO from "../components/seo"

const RECAPTCHA_SITE_KEY = "6LcdjcYZAAAAAN6IRwOf7UNuk9-Yf8jY4UHWGOjP"

const ContactUs = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = useState({})
  const [state, setState] = React.useState({
    callback: "not fired",
    value: "",
    expired: false,
  })
  const _reCaptchaRef = React.createRef()

  const handleSubmit = () => {
    if (!validate()) return

    let body = new FormData(body)
    body.append("name", name)
    body.append("email", email)
    body.append("message", message)
    setLoading(true)
    fetch("https://api.yourrepairexperts.com/index.php", {
      method: "POST",
      mode: "no-cors",
      "Access-Control-Allow-Origin": "*",
      body,
    })
      .then(({}) => {
        setEmail("")
        setMessage("")
        setName("")
        setLoading(false)
        Swal.fire({
          title: "Thank You",
          text: "We will contact you soon.",
          icon: "success",
          confirmButtonText: "Okay",
        })
      })
      .catch(({}) => {
        Swal.fire({
          title: "Error",
          text: "Something went wrong at our end. Please try again.",
          icon: "error",
          confirmButtonText: "Okay",
        })
      })
  }

  const validate = () => {
    const errors = {}
    if (_.isEmpty(name)) errors.name = "This field is required"
    if (!email.includes("@")) errors.email = "Please enter a valid email"
    if (_.isEmpty(email)) errors.email = "This field is required"
    if (_.isEmpty(message)) errors.message = "This field is required"
    if (_.isEmpty(state.value))
      errors.recaptcha = "Please verify that you are not a robot"
    setError(errors)
    return _.isEmpty(errors)
  }

  const remvoeError = ({ target: { name } }) => {
    setError({ ...error, [name]: "" })
  }

  const handleChange = value => {
    setError({ ...error, recaptcha: "" })
    setState({ value })
    if (value === null) setState({ expired: true })
  }

  const asyncScriptOnLoad = () => {
    setState({ callback: "called!" })
  }

  return (
    <Layout>
      <SEO title="Contact Us" description="" />
      <Container className="mt-50 pb-5">
        <iframe
          title="Office Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3565.5829499978554!2d-80.26707328487751!3d26.661832383236234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d92ff01b348051%3A0x6e2a7d3a22d12758!2s1300+Corporate+Center+Way+%23105c%2C+Wellington%2C+FL+33414!5e0!3m2!1sen!2s!4v1479425071387"
          height={450}
          allowFullScreen
          className="map-iframe w-100 border-0 mt-3"
        ></iframe>
        <div className="bg-white mt-4 p-5">
          <Row>
            <Col xs={12} md={6}>
              <div className="contact-us-section">
                <h4 className="text-dark font-weight-bolder">Get in Touch</h4>
                <hr className="w-25 ml-0 bg-dark" />
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faMailBulk} className="text-dark" />
                    <span className="ml-3">sales@yourrepairexperts.com</span>
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faLocationArrow}
                      className="text-dark"
                    />
                    <span className="ml-3">
                      1300 CORPORATE CENTER WAY SUITE # 105C WELLINGTON, FL
                      33414
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={6} className="p-auto px-md-5 mt-2 mt-md-0">
              <Form className="mt-3">
                <FormGroup>
                  <Label for="name">Your Name</Label>
                  <Input
                    type="text"
                    name="name"
                    onFocus={remvoeError}
                    value={name}
                    onChange={({ target: { value } }) => setName(value)}
                  />
                  {error.name && (
                    <span className="text-danger">
                      <small>{error.name}</small>
                    </span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="email">Your Email</Label>
                  <Input
                    type="email"
                    name="email"
                    value={email}
                    onFocus={remvoeError}
                    onChange={({ target: { value } }) => setEmail(value)}
                  />
                  {error.email && (
                    <span className="text-danger">
                      <small>{error.email}</small>
                    </span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="message">Message</Label>
                  <Input
                    type="textarea"
                    name="message"
                    rows="2"
                    className="text-area"
                    value={message}
                    onFocus={remvoeError}
                    onChange={({ target: { value } }) => setMessage(value)}
                  />
                  {error.message && (
                    <span className="text-danger">
                      <small>{error.message}</small>
                    </span>
                  )}
                </FormGroup>
                <FormGroup>
                  <ReCAPTCHA
                    style={{ display: "inline-block" }}
                    theme="light"
                    ref={_reCaptchaRef}
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={handleChange}
                    asyncScriptOnLoad={asyncScriptOnLoad}
                  />
                  <br />
                  {error.recaptcha && (
                    <span className="text-danger">
                      <small>{error.recaptcha}</small>
                    </span>
                  )}
                </FormGroup>
                <Button
                  color="dark"
                  className="w-100 d-flex align-items-center justify-content-center"
                  onClick={handleSubmit}
                >
                  Send Message
                  {loading && (
                    <span
                      class="spinner-border spinner-border-sm ml-3"
                      role="status"
                    />
                  )}
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  )
}

export default ContactUs
